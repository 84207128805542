@import "../../../styles";

.gameCanvas {
   box-sizing: border-box;
   user-select: none;

   background: url("/assets/background.png");

   font-size: 1rem;

   opacity: 0;

   canvas {
      width: 100%;
      height: 100%;
   }

   .counter {
      box-sizing: border-box;

      position: absolute;

      left: 3%;
      bottom: 3%;

      border-radius: 1em;

      font-size: 1.5rem;
      font-family: $poppinsBlack;
      color: $coinDropBlack;
      line-height: 1.1;

      & > :nth-child(n) {
         margin: 0;
      }
   }

   .timer {
      width: 8em;
      height: 8em;

      position: absolute;
      left: 5%;
      top: 5%;

      .seconds {
         font-size: 2rem;
         font-family: $poppinsBlack;

         @media screen and (max-height: 399px) {
            font-size: 1rem;
         }
      }
   }
}

.gameOver {
   box-sizing: border-box;

   width: 100%;
   height: 100%;

   background: url("/assets/background.png");

   line-height: 1.1;
   color: $fitnessBlack;

   border-radius: 1.5em;

   background-color: $coinDropGray;

   .playAgainContent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5em;

      opacity: 0;
      display: none;

      .title {
         font-family: $poppinsBlack;
         font-size: 10em;

         margin: 0;

         text-transform: uppercase;
      }

      .playAgain {
         cursor: pointer;

         font-family: $poppinsMedium;
         font-size: 2em;
         color: $fitnessBlack;

         padding: 0.75em 2em;

         border-radius: 0.75em;
         border: 0.25em $coinDropGray solid;

         background-color: transparent;

         margin: 0;
      }
   }
}

@media screen and (max-width: 1024px) {
   .gameCanvas {
      font-size: 0.8rem;
   }
}

@media screen and (max-width: 768px) {
   .gameCanvas {
      font-size: 0.6rem;
   }
}

@media screen and (max-width: 425px) {
   .gameCanvas {
      font-size: 0.5rem;
   }
}

@media screen and (max-width: 320px) {
   .gameCanvas {
      font-size: 0.4rem;
   }
}
