.iconed-button {
   background-color: transparent;

   border: none;

   cursor: pointer;

   transition: all 0.1s ease-in-out;
}

.iconed-button:active {
   transform: scale(0.98);

   transition: all 0.1s ease-in-out;
}
