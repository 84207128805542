.landing {
    font-size: 0.8rem;

    flex-grow: 1;
    width: 100%;

    opacity: 0;

    .contentWrapper {
        height: 50%;
        width: 70%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3em;
    }
}

@media screen and (max-width: 1024px) {
    .landing {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 768px) {
    .landing {
        font-size: 0.6rem;
    }
}

@media screen and (max-width: 540px) {
    .landing {
        font-size: 0.5rem;

        .contentWrapper {
            width: 80%;
        }
    }
}

@media screen and (max-width: 375px) {
    .landing {
        font-size: 0.4rem;
    }
}
