@import "../../../../styles";

.button {
   line-height: 1.1;
   color: $coinDropBlack;
   font-family: $poppinsBlack;
   font-size: 3.125em;
   text-transform: uppercase;

   background-color: transparent;

   border: none;

   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.5em;

   &:focus {
      outline: none;
   }

   svg {
      height: 0.75em;

      path {
         fill: $coinDropBlack;
      }
   }

   @media screen and (max-height: 500px) {
      font-size: 2em;
   }
}
