.tutorial {
    flex-grow: 1;
    width: 100%;

    opacity: 0;

    font-size: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;

    transition: 0.2s ease-in-out all;
}

/* MIXED DIMENSIONS */

@media screen and (max-height: 900px) and (min-width: 1600px) {
    .tutorial {
        font-size: 0.9rem;
    }
}

@media screen and (max-height: 800px) and (min-width: 1600px) {
    .tutorial {
        font-size: 0.8rem;
    }
}

@media screen and (max-height: 700px) and (min-width: 1600px) {
    .tutorial {
        font-size: 0.7rem;
    }
}

@media screen and (max-height: 600px) and (min-width: 1600px) {
    .tutorial {
        font-size: 0.6rem;
    }
}

/* HORIZONTAL DIMENSION */

@media screen and (max-width: 1600px) {
    .tutorial {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 1200px) {
    .tutorial {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 1024px) {
    .tutorial {
        font-size: 0.6rem;
    }
}

@media screen and (max-width: 768px) {
    .tutorial {
        font-size: 0.5rem;
    }
}
