@import "../../../styles";

.pulsingButton {
   position: relative;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   padding: 1em 2em;

   background-color: transparent;

   cursor: pointer;

   white-space: nowrap;

   &:hover {
      background-color: rgba($coinDropGreen, 0.1);
   }

   .pulsingBorder {
      border: 0.25rem solid $coinDropGreen;

      position: absolute;
      width: 100%;
      height: 100%;
   }

   .button {
      width: 100%;
      height: 100%;

      cursor: pointer;
   }
}
