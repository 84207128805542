@import "../../../../styles";

.mobileTutorial {
   flex-grow: 1;
   width: 100%;

   opacity: 0;

   font-size: 1rem;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   transition: 0.2s ease-in-out all;

   .logo {
      @media screen and (max-height: 500px) {
         width: 40px;
      }
   }

   .mobileTutorialSection {
      width: 80%;
      height: 65%;

      background-color: white;

      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      border: 1.25em solid $coinDropBlack;
      border-radius: 3em;

      & > :nth-child(1) {
         position: absolute;
         width: 10em;

         bottom: 107.5%;
         left: 50%;
         transform: translate(-50%, 50%);

         @media screen and (max-height: 500px) {
            width: 8em;
         }
      }
   }
}

@media screen and (max-width: 620px) {
   .mobileTutorial {
      font-size: 0.7rem;
   }
}
