/* GLOBAL COLORS */
$primary: #0e8acb !global;
$secondary: #1b2f5a !global;

/* PET SIMULATOR COLORS */
$petYellow: #fed67b !global;
$petBrown: #3f2208 !global;
$heartBasic: #f72342 !global;
$heartHover: #bf1c34 !global;
$heartPressed: #b2152c !global;
$barsGrey: #d9d7d7 !global;

/* RAFFLE COLORS */

$raffleHeaderGradientHalfOpacity: linear-gradient(#ff3fef67, #8c00aa67);
$raffleHeaderGradient: linear-gradient(#ff3fef, #8c00aa);
$raffleYellow: #eab60f;
$rafflePink: #f63ae9;
$raffleBlack: #292929;

/* FITNESS REWARD COLORS */

$fitnessGray: #d4d4d427;
$fitnessGrayHover: #d4d4d470;
$fitnessBlack: #3f3f3f;
$fitnessHeartScale: linear-gradient(to top, white, #6cff9d, #ffd632, #ff0000);
$fitnessHeartScaleRight: linear-gradient(to right, white, #6cff9d, #ffd632, #ff0000);

/* COIN DROP COLORS */

$coinDropGray: #383838;
$coinDropBlack: #212121;
$coinDropGreen: #17ba09;

/* ROCKING COLORS */

$rockingGray: #707070;
$rockingViolet: #5300aa;
$rockingGreenGradient: linear-gradient(to bottom, #00ff10, #43edb3);
$rockingLiteViolet: #5300aa;

/* FONTS */

$poppinsBold: 'PoppinsBold', sans-serif;
$poppinsRegular: 'PoppinsRegular', sans-serif;
$poppinsMedium: 'PoppinsMedium', sans-serif;
$poppinsSemiBold: 'PoppinsSemiBold', sans-serif;
$poppinsBlack: 'PoppinsBlack', sans-serif;

/* SHADOWS */

$shadowMdEm: 0 0.25em 0.75em rgba(0, 0, 0, 0.16);
$shadowMdRem: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.16);
