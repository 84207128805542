@import "../../../../styles";

.explanation {
   width: 80%;
   height: 55%;

   margin: 0 auto;

   position: relative;

   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   gap: 1.5em;

   border: 1.25em solid $coinDropBlack;
   border-radius: 3em;

   background-color: white;

   padding: 3em;
   box-sizing: border-box;

   // .logo {
   //     position: absolute;
   //     bottom: 87.5%;
   //     left: 50%;
   //     transform: translateX(-50%);

   //     width: 16em;

   //
   // }

   &:nth-last-of-type() {
      position: absolute;
      width: 14em;

      bottom: 107.5%;
      left: 50%;
      transform: translate(-50%, 50%);
   }

   .divider {
      height: 70%;
      width: 1em;

      background-color: rgba($coinDropBlack, 0.5);

      border-radius: 1em;
   }
}
