html,
body {
   margin: 0;
   padding: 0;

   width: 100vw;
   width: 100dvw;
   height: 100vh;
   height: 100dvh;

   overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
   margin: 0;
}

#root {
   display: flex;

   width: 100%;
   height: 100%;
}

main {
   flex-grow: 1;
   width: 100%;
   height: 100%;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   box-sizing: border-box;

   display: flex;

   &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("./assets/background.png");
      background-size: cover;
      filter: blur(1.5rem);
      z-index: -1;
   }
}
