@import "../../../styles";

.summary {
   width: 60%;
   height: 60%;

   opacity: 0;

   font-size: 1rem;

   margin: auto;

   padding: 7em 5em 0;

   box-sizing: border-box;

   border-radius: 3em;
   border: 1.25em solid $coinDropBlack;

   background-color: white;

   position: relative;

   & > :nth-child(1) {
      position: absolute;
      width: 16em;

      bottom: 105%;
      left: 50%;
      transform: translate(-50%, 50%);
   }

   .summaryContent {
      width: 100%;
      height: 100%;

      opacity: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
   }

   .title {
      white-space: nowrap;
      text-align: center;
      font-size: 2.5em;
      font-family: $poppinsSemiBold;
      color: $coinDropBlack;
      text-transform: uppercase;
      line-height: 1.1;
      margin: 0;

      span {
         font-family: $poppinsBold;
      }
   }

   .points {
      font-size: 9em;
      font-family: $poppinsBlack;
      color: $coinDropBlack;
      line-height: 1.1;
      margin: 0;

      transition: 0.1s ease-in-out all;

      @media screen and (max-width: 768px) {
         font-size: 6em;
      }
   }

   .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2em;

      width: 45%;

      margin: 0 auto;

      transition: 0.1s ease-in-out all;

      .iconedButton {
         svg {
            width: 5.5em;

            transition: 0.1s ease-in-out all;

            path {
               fill: $coinDropBlack;

               transition: 0.1s ease-in-out all;
            }
         }

         &:hover {
            .icon {
               path {
                  fill: rgba($coinDropBlack, 0.6);
               }
            }
         }
      }
   }
}

/* MIXED DIMENSIONS */

@media screen and (max-height: 900px) and (min-width: 1600px) {
   .summary {
      font-size: 0.9rem;
   }
}

@media screen and (max-height: 800px) and (min-width: 1600px) {
   .summary {
      font-size: 0.8rem;
   }
}

@media screen and (max-height: 700px) and (min-width: 1600px) {
   .summary {
      font-size: 0.7rem;
   }
}

@media screen and (max-height: 600px) and (min-width: 1600px) {
   .summary {
      font-size: 0.6rem;
   }
}

/* HORIZONTAL DIMENSION */

@media screen and (max-width: 1600px) {
   .summary {
      font-size: 0.9rem;
   }
}

@media screen and (max-width: 1200px) {
   .summary {
      font-size: 0.8rem;
   }
}

@media screen and (max-width: 1024px) {
   .summary {
      font-size: 0.7rem;
   }
}

@media screen and (max-width: 768px) {
   .summary {
      font-size: 0.6rem;

      width: 80%;

      .buttons {
         .iconedButton {
            svg {
               width: 3em;
            }
         }
      }
   }
}

@media screen and (max-height: 300px) {
   .summary {
      font-size: 0.5rem;

      .buttons {
         .iconedButton {
            svg {
               width: 2.5em !important;
            }
         }
      }
   }
}

@media screen and (max-width: 620px) {
   .summary {
      padding: 7em 2em 0;
   }
}

@media screen and (max-width: 540px) {
   .summary {
      font-size: 0.5rem;
   }
}

@media screen and (max-width: 425px) {
   .summary {
      font-size: 0.4rem;

      height: 50%;
   }
}

@media screen and (max-width: 375px) {
   .summary {
      font-size: 0.3rem;

      .buttons {
         .iconedButton {
            .svg {
               width: 2.5em;
            }
         }
      }
   }
}
