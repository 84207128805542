@import "../../../../../styles";

.explanationItem {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;

   box-sizing: border-box;

   height: 100%;
   width: 100%;

   position: relative;

   line-height: 1.1;
   font-family: $poppinsBlack;

   .rewardIcon {
      height: 2.5em;

      position: absolute;
      top: 5%;
      right: 5%;

      svg {
         height: 100%;
         width: 100%;
      }
   }

   .item {
      height: 80%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
         height: 50%;
         width: 80%;
      }
   }

   .title {
      color: $coinDropBlack;
      font-size: 2em;
      text-align: center;

      margin: 0;

      width: 100%;

      position: absolute;
      bottom: 0%;
      left: 50%;
      transform: translateX(-50%);
   }
}
