/* CAPITANA */

@font-face {
   font-family: "Capitana Bold";
   src: url("../../fonts/Capitana/Capitana-Bold.ttf");
   src: local("Capitana Capitana-Bold.ttf"), local("Capitana-Bold.ttf"),
      url("../../fonts/Capitana/Capitana-Bold.ttf") format("truetype");
   font-weight: 700;
   font-style: normal;
}

@font-face {
   font-family: "Capitana Regular";
   src: url("../../fonts/Capitana/Capitana-Regular.ttf");
   src: local("Capitana Capitana-Regular.ttf"), local("Capitana-Regular.ttf"),
      url("../../fonts/Capitana/Capitana-Regular.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Capitana Light";
   src: url("../../fonts/Capitana/Capitana-Light.ttf");
   src: local("Capitana Capitana-Light.ttf"), local("Capitana-Light.ttf"),
      url("../../fonts/Capitana/Capitana-Light.ttf") format("truetype");
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: "Capitana Extralight";
   src: url("../../fonts/Capitana/Capitana-Extralight.ttf");
   src: local("Capitana Capitana-Extralight.ttf"),
      local("Capitana-Extralight.ttf"),
      url("../../fonts/Capitana/Capitana-Extralight.ttf") format("truetype");
   font-weight: 200;
   font-style: normal;
}

@font-face {
   font-family: "Capitana Medium";
   src: url("../../fonts/Capitana/Capitana-Medium.ttf");
   src: local("Capitana Capitana-Medium.ttf"), local("Capitana-Medium.ttf"),
      url("../../fonts/Capitana/Capitana-Medium.ttf") format("truetype");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "Capitana Semibold";
   src: url("../../fonts/Capitana/Capitana-Semibold.ttf");
   src: local("Capitana Capitana-Semibold.ttf"), local("Capitana-Semibold.ttf"),
      url("../../fonts/Capitana/Capitana-Semibold.ttf") format("truetype");
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: "Capitana Black";
   src: url("../../fonts/Capitana/Capitana-Black.ttf");
   src: local("Capitana Capitana-Black.ttf"), local("Capitana-Black.ttf"),
      url("../../fonts/Capitana/Capitana-Black.ttf") format("truetype");
   font-weight: 900;
   font-style: normal;
}

/* AVENIR */

@font-face {
   font-family: "AvenirRegular";
   src: url("../../fonts/Avenir/Avenir-regular.ttf");
   src: local("Avenir Avenir-regular.ttf"), local("Avenir-regular.ttf"),
      url("../../fonts/Avenir/Avenir-regular.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "AvenirBlack";
   src: local("Avenir Avenir-black.ttf"), local("Avenir-black.ttf"),
      url("../../fonts/Avenir/Avenir-black.ttf") format("truetype");
   font-weight: 800;
   font-style: normal;
}

@font-face {
   font-family: "AvenirHeavy";
   src: local("Avenir Avenir-heavy.ttf"), local("Avenir-heavy.ttf"),
      url("../../fonts/Avenir/Avenir-heavy.ttf") format("truetype");
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: "AvenirLight";
   src: url("../../fonts/Avenir/Avenir-light.ttf");
   src: local("Avenir Avenir-light.ttf"), local("Avenir-light.ttf"),
      url("../../fonts/Avenir/Avenir-light.ttf") format("truetype");
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: "AvenirBook";
   src: url("../../fonts/Avenir/Avenir-book.ttf");
   src: local("Avenir Avenir-book.ttf"),
      url("../../fonts/Avenir/Avenir-book.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
}

/* POPPINS */

@font-face {
   font-family: "PoppinsRegular";
   src: url("../../fonts/Poppins/Poppins-Regular.ttf");
   src: local("Poppins-Regular.ttf"),
      url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "PoppinsMedium";
   src: url("../../fonts/Poppins/Poppins-Medium.ttf");
   src: local("Poppins-Medium.ttf"),
      url("../../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "PoppinsSemiBold";
   src: url("../../fonts/Poppins/Poppins-SemiBold.ttf");
   src: local("Poppins-SemiBold.ttf"),
      url("../../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: "PoppinsBold";
   src: url("../../fonts/Poppins/Poppins-Bold.ttf");
   src: local("Poppins-Bold.ttf"),
      url("../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
   font-weight: 700;
   font-style: normal;
}

@font-face {
   font-family: "PoppinsBoldItalic";
   src: url("../../fonts/Poppins/Poppins-BoldItalic.ttf");
   src: local("Poppins-BoldItalic.ttf"),
      url("../../fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
   font-weight: 800;
   font-style: normal;
}

@font-face {
   font-family: "Poppins ExtraBold";
   src: url("../../fonts/Poppins/Poppins-ExtraBold.ttf");
   src: local("Poppins-ExtraBold.ttf"),
      url("../../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
   font-weight: 800;
   font-style: normal;
}

@font-face {
   font-family: "PoppinsBlack";
   src: url("../../fonts/Poppins/Poppins-Black.ttf");
   src: local("Poppins-Black.ttf"),
      url("../../fonts/Poppins/Poppins-Black.ttf") format("truetype");
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: "Poppins Light";
   src: url("../../fonts/Poppins/Poppins-Light.ttf");
   src: local("Poppins-Light.ttf"),
      url("../../fonts/Poppins/Poppins-Light.ttf") format("truetype");
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: "Poppins ExtraLight";
   src: url("../../fonts/Poppins/Poppins-ExtraLight.ttf");
   src: local("Poppins-ExtraLight.ttf"),
      url("../../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
   font-weight: 200;
   font-style: normal;
}

@font-face {
   font-family: "Poppins Thin";
   src: url("../../fonts/Poppins/Poppins-Thin.ttf");
   src: local("Poppins-Thin.ttf"),
      url("../../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
   font-weight: 100;
   font-style: normal;
}

@font-face {
   font-family: "PoppinsSemiBoldItalic";
   src: url("../../fonts/Poppins/Poppins-SemiBoldItalic.ttf");
   src: local("Poppins-SemiBoldItalic.ttf"),
      url("../../fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
   font-weight: 600;
   font-style: normal;
}
