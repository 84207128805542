@import "../../../../../styles";

.mobileTutorialItem {
   width: 100%;
   height: fit-content;
   padding: 0 2em;
   box-sizing: border-box;

   font-size: 0.8rem;

   position: relative;

   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 1em;

   box-sizing: border-box;

   .divider {
      height: 90%;
      width: 0.5em;

      background-color: rgba($coinDropBlack, 0.5);

      border-radius: 1em;
   }

   .item {
      width: 6.5em;

      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
         height: 70%;
         width: 80%;
      }

      .reward {
         position: absolute;

         right: -55%;
         top: -20%;

         width: 5em;

         svg {
            height: 100%;
            width: 100%;
         }
      }
   }

   .title {
      width: 50%;

      margin: 0;

      color: $coinDropBlack;
      font-family: $poppinsSemiBold;
      font-size: 2em;

      text-align: left;

      @media screen and (max-height: 500px) {
         font-size: 0.6rem;
      }

      span {
         font-family: $poppinsBold;
      }
   }
}

/* MIXED DIMENSIONS */

@media screen and (max-height: 750px) and (min-width: 620px) {
   .mobileTutorialItem {
      font-size: 0.7rem;
   }
}

@media screen and (max-height: 600px) and (min-width: 620px) {
   .mobileTutorialItem {
      font-size: 0.6rem;
   }
}

@media screen and (max-height: 750px) and (min-width: 540px) and (max-width: 620px) {
   .mobileTutorialItem {
      font-size: 0.6rem;
   }
}

@media screen and (max-height: 600px) and (min-width: 540px) and (max-width: 620px) {
   .mobileTutorialItem {
      font-size: 0.5rem;
   }
}

/* HORIZONTAL DIMENSION */

@media screen and (max-width: 620px) {
   .mobileTutorialItem {
      font-size: 0.7rem;
   }
}

@media screen and (max-width: 540px) {
   .mobileTutorialItem {
      font-size: 0.6rem;
   }
}

@media screen and (max-width: 475px) {
   .mobileTutorialItem {
      font-size: 0.5rem;
   }
}

@media screen and (max-width: 425px) {
   .mobileTutorialItem {
   }
}

@media screen and (max-width: 375px) {
   .mobileTutorialItem {
      font-size: 0.4rem;
   }
}
